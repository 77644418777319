<template>
  <div class="">
    <van-nav-bar title="工单查询" left-arrow  @click-left="$router.go(-1)"/>
    <div class="content-padding">
      <van-cell-group v-if="queryType=='no'">
        <van-field v-model="query.no_code" label="服务/快递单号" />
        <van-field v-model="query.captcha" label="验证码">
          <img slot="button" :src="captchaUrl + random" @click="random = randomCode()">
        </van-field>
        <van-button type="default" class="submit" @click="loadDataByNo" block style="margin-top: 20px">查询</van-button>
      </van-cell-group>

      <van-panel :title="record.no" desc="" :status="record.status_name" v-for="record in data" :key="record.id">
        <p>渠道: {{  record.channel_name }}</p>
        <p>产品: {{  record.product_name }}</p>
        <p>服务类型: {{  record.receive_type_name }}</p>
        <p>到达日期: {{  record.receive_date_web   }}</p>
        <p>发货信息: {{  record.express_out_type_name }} {{ record.express_no_out }}</p>
        <p>提交时间: {{  record.created_at }}</p>
      </van-panel>
    </div>
  </div>
</template>

<script>
export default {
  name: 'repair-list',
  data () {
    return {
      queryType: this.$route.query.type ? this.$route.query.type : 'phone',
      data: [],
      random: this.randomCode(),
      captchaUrl: 'https://sale.huami.com/v2/captchas/image?code=',
      query: {},
      uploadUrl: 'https://sale.huami.com/v2/pmp-records/upload-file'
    }
  },
  created () {
    if (this.queryType === 'phone') {
      this.loadData()
    }
  },
  methods: {
    randomCode () {
      return Math.random().toString().replace('0.', '')
    },
    loadData () {
      this.$http.get('pmp-records').then((res) => {
        this.data = res.data
      }).catch((error) => {
        this.$toast.fail(error.response.data.message)
      })
    },
    loadDataByNo () {
      this.query.captcha_code = this.random

      this.$http.get('pmp-records/query-by-no', { params: this.query }).then((res) => {
        this.data = []
        this.data.push(res.data)
      }).catch((error) => {
        this.$toast.fail(error.response.data.message)
      })
    }
  }
}
</script>

<style lange="scss" scoped>

</style>
